













































































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import useWindowResize from '~/hooks/useResizeWindow.js';
import useUrlFormatter from '~/hooks/useUrlFormatter';
import {
  getStatusReferenceEnum,
  StatusReference as StatusReferenceEnum
} from '@/helpers/utils';

export type Product = {
  id: number;
  name: string;
  media: string;
  first_promotion: string;
  total_reviews: number;
  rating_number: number;
  status: string;
  status_reference: number;
  price: number;
  first_price: number;
  manufacturers_suggested_retail_price: number;
  price_after_cash_discount: number;
  price_after_cashback: number;
  price_after_cashback_discount: number;
  url: string;
  category: string;
  brand: string;
  spli_id: number;
  sku: string;
  sequence?: number;
  is_occasion: boolean;
  can_be_ordered: boolean;
  has_blackfriday_deal: boolean;
};

export default defineComponent({
  name: 'ProductCard',
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
      default: () => ({
        id: 0,
        name: '',
        media: '',
        first_promotion: '',
        total_reviews: 0,
        rating_number: 0,
        status: '',
        status_reference: 0,
        price: 0,
        first_price: 0,
        manufacturers_suggested_retail_price: 0,
        price_after_cashback_discount: 0,
        url: '',
        spli_id: 0,
        sku: ''
      })
    },
    gtmListName: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { isDesktop } = useWindowResize();
    const formatUrl = useUrlFormatter();

    const productStatusReference = computed(() => {
      return getStatusReferenceEnum(props.product?.status_reference || 0);
    });

    const labelVariant = computed(() => {
      if (
        [
          StatusReferenceEnum.ON_ORDER,
          StatusReferenceEnum.PRE_ORDER,
          StatusReferenceEnum.TEMPORARILY_SOLD_OUT
        ].includes(productStatusReference.value)
      ) {
        return 'soon-available';
      }
      if (props.product?.can_be_ordered === false) {
        return 'not-available';
      }
      return 'available';
    });

    return {
      isDesktop,
      labelVariant,
      formatUrl,
      productStatusReference,
      StatusReferenceEnum
    };
  }
});
